<template>
  <div
    class="tab-item-container"
    :class="{ 'disabled': isTabDisabled }"
  >
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Web.Settings.Dc.replenishmentParams') }}
      </span>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ defaultLeadTimeLabel }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="defaultLeadTimeModel"
          rules="required|between:0,9999"
        >
          <SlControlInput
            v-model="defaultLeadTimeModel"
            :is-invalid="invalid"
            :min="0"
            :max="9999"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ defaultOrderCycleLabel }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="defaultOrderCycleModel"
          rules="required|between:1,99"
        >
          <SlControlInput
            v-model="defaultOrderCycleModel"
            :is-invalid="invalid"
            :min="1"
            :max="99"
            :disabled="isDefaultOrderCycleDisabled"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ shelfDiscardLabel }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="shelfDiscardModel"
          rules="required|between:1,99"
        >
          <SlControlInput
            v-model="shelfDiscardModel"
            :is-invalid="invalid"
            :min="1"
            :max="99"
          />
        </SlValidate>
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('WiInventorySettings.Ui.grpSafetyStock') }}
      </span>
      <div class="tab-item-container__block-row">
        <span class="body-1-sb">
          {{ $t('Settings.Inventory.lbMaximumOf') }}
        </span>
      </div>
      <div class="tab-item-container__block-row">
        <SlCheckbox
          v-model="enableServiceLevelModel"
          :label="enableServiceLevelLabel"
          class="tab-item-container__label"
        />
        <SlValidate
          v-slot="{ invalid }"
          vid="serviceLevelModel"
          rules="required|between:0.1,99.9"
        >
          <SlControlInput
            v-model="serviceLevelModel"
            :is-invalid="invalid"
            :disabled="!enableServiceLevelModel"
            :min="0.1"
            :max="99.9"
            :fixed="1"
            :step="1"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__block-column tab-item-container__column-folded">
          <SlCheckbox
            v-model="enableFutureSalesModel"
            :indeterminate="isFutureSalesModelIndeterminate"
            :label="this.$t('WiInventorySettings.Ui.chkFutureDemand')"
            class="tab-item-container__label"
          />
          <div class="tab-item-container__block-column tab-item-container__column-folded tab-item-container__column-offset">
            <div class="tab-item-container__block-row">
              <SlCheckbox
                v-model="futureSalesNPeriodsEnabled"
                :label="salesDemandLabel"
                class="tab-item-container__label"
              />
              <SlValidate
                v-slot="{ invalid }"
                vid="futureSalesNPeriodsModel"
                rules="required|between:0,100"
              >
                <SlControlInput
                  v-model="futureSalesNPeriodsModel"
                  :is-invalid="invalid"
                  :min="0"
                  :max="100"
                  :fixed="1"
                  :step="0.1"
                  :disabled="!futureSalesNPeriodsEnabled"
                />
              </SlValidate>
            </div>
            <div class="tab-item-container__block-row">
              <SlCheckbox
                v-model="futureDcNPeriodsEnabled"
                :label="lowerEchelonLabel"
                class="tab-item-container__label"
              />
              <SlValidate
                v-slot="{ invalid }"
                vid="futureDcNPeriodsModel"
                rules="required|between:0,100"
              >
                <SlControlInput
                  v-model="futureDcNPeriodsModel"
                  :is-invalid="invalid"
                  :min="0"
                  :max="100"
                  :fixed="1"
                  :step="0.1"
                  :disabled="!futureDcNPeriodsEnabled"
                />
              </SlValidate>
            </div>
            <div
              v-if="hasBom"
              class="tab-item-container__block-row"
            >
              <SlCheckbox
                v-model="futureBomNPeriodsEnabled"
                :label="materialConsumptionLabel"
                class="tab-item-container__label"
              />
              <SlValidate
                v-slot="{ invalid }"
                vid="futureBomNPeriodsModel"
                rules="required|between:0,100"
              >
                <SlControlInput
                  v-model="futureBomNPeriodsModel"
                  :is-invalid="invalid"
                  :min="0"
                  :max="100"
                  :fixed="1"
                  :step="0.1"
                  :disabled="!futureBomNPeriodsEnabled"
                />
              </SlValidate>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('WiInventorySettings.Ui.grpPlan') }}
      </span>
      <div class="tab-item-container__block-row">
        <span class="tab-item-container__label">
          {{ $t('Settings.Inventory.lbType') }}
        </span>
        <SlControl
          v-model="orderStrategyModel"
          :options="orderStrategyOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  orderCycleUnitLabelByValue,
  orderCycleValues,
  orderStrategyKeys,
  orderStrategyOptions
} from '@/config/shared/settings.config';

export default {
  name: 'DCTab',
  props: {
    disabled: Boolean
  },
  data() {
    return {
      orderStrategyKeys,
      serviceLevelMaxValue: 99.9
    };
  },
  computed: {
    ...mapState('settings', [
      'settings'
    ]),
    ...mapGetters({
      hasInventory: 'project/hasInv',
      hasDistribution: 'project/hasDistrib',
      period: 'project/period',
      hasBom: 'project/hasBom'
    }),
    orderCycleUnitLabelByValue() {
      return orderCycleUnitLabelByValue(this.period, this);
    },
    orderCycleUnitValue() {
      return this.settings?.orderCycleUnit;
    },
    orderCycleUnitLabel() {
      return this.orderCycleUnitLabelByValue[this.orderCycleUnitValue];
    },
    isTabDisabled() {
      return this.disabled || !this.hasInventory || !this.hasDistribution;
    },
    isDefaultOrderCycleDisabled() {
      return this.orderCycleUnitValue === orderCycleValues.LEADTIME;
    },
    defaultLeadTimeLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbLeadTime')}, ${this.$t('WiInventorySettings.Ui.lbLeadTime2')}`;
    },
    defaultOrderCycleLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbOrderCycle')}, ${this.orderCycleUnitLabel}`;
    },
    shelfDiscardLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbDiscard')}, %`;
    },
    enableServiceLevelLabel() {
      return `${this.$t('WiInventorySettings.Ui.chkServiceLevel')}, ${this.$t('WiInventorySettings.Ui.lbServiceLevelPc')}`;
    },
    enableFutureSalesLabel() {
      return `${this.$t('WiInventorySettings.Ui.chkFutureDemand')}, ${this.$t(`Common.AfterNum.${this.period}`)}`;
    },
    salesDemandLabel() {
      return `${this.$t('Web.Settings.Inventory.salesDemandLabel')}, ${this.$t(`Common.AfterComma.${this.period}`)}`;
    },
    lowerEchelonLabel() {
      return `${this.$t('Web.Settings.Inventory.lowerEchelonLabel')}, ${this.$t(`Common.AfterComma.${this.period}`)}`;
    },
    materialConsumptionLabel() {
      return `${this.$t('Web.Settings.Inventory.materialConsumptionLabel')}, ${this.$t(`Common.AfterComma.${this.period}`)}`;
    },
    orderStrategyOptions() {
      return orderStrategyOptions(this);
    },
    defaultLeadTimeModel: {
      get() {
        return this.settings?.distrib?.defaultLeadTime;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'defaultLeadTime',
          value
        });
      }
    },
    defaultOrderCycleModel: {
      get() {
        return this.settings?.distrib?.defaultOrderCycle;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'defaultOrderCycle',
          value
        });
      }
    },
    shelfDiscardModel: {
      get() {
        return this.settings?.distrib?.shelfDiscard;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'shelfDiscard',
          value
        });
      }
    },
    enableServiceLevelModel: {
      get() {
        return this.settings?.distrib?.enableServiceLevel;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'enableServiceLevel',
          value
        });
      }
    },
    serviceLevelModel: {
      get() {
        return this.settings?.distrib?.serviceLevel;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'serviceLevel',
          value: +value > this.serviceLevelMaxValue
            ? this.serviceLevelMaxValue
            : value
        });
      }
    },
    enableFutureSalesModel: {
      get() {
        return Object.values(this.allFutureSalesModels).some(model => model);
      },
      set(value) {
        Object.keys(this.allFutureSalesModels).forEach(modelKey =>
          this.setSettingsByKey({
            key: 'distrib',
            subKey: modelKey,
            value
          })
        );
      }
    },
    futureSalesNPeriodsModel: {
      get() {
        return this.settings?.distrib?.futureSalesNPeriods;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'futureSalesNPeriods',
          value
        });
      }
    },
    futureDcNPeriodsModel: {
      get() {
        return this.settings?.distrib?.futureDcNPeriods;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'futureDcNPeriods',
          value
        });
      }
    },
    futureBomNPeriodsModel: {
      get() {
        return this.settings?.distrib?.futureBomNPeriods;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'futureBomNPeriods',
          value
        });
      }
    },
    orderStrategyModel: {
      get() {
        return this.settings?.distrib?.orderStrategy;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'orderStrategy',
          value
        });
      }
    },
    futureSalesNPeriodsEnabled: {
      get() {
        return this.settings?.distrib?.futureSalesNPeriodsEnabled;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'futureSalesNPeriodsEnabled',
          value
        });
      }
    },
    futureDcNPeriodsEnabled: {
      get() {
        return this.settings?.distrib?.futureDcNPeriodsEnabled;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'futureDcNPeriodsEnabled',
          value
        });
      }
    },
    futureBomNPeriodsEnabled: {
      get() {
        return this.settings?.distrib?.futureBomNPeriodsEnabled;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'distrib',
          subKey: 'futureBomNPeriodsEnabled',
          value
        });
      }
    },
    allFutureSalesModels() {
      return {
        futureSalesNPeriodsEnabled: this.futureSalesNPeriodsEnabled,
        futureDcNPeriodsEnabled: this.futureDcNPeriodsEnabled,
        ...(this.hasBom) && {
          futureBomNPeriodsEnabled: this.futureBomNPeriodsEnabled
        }
      };
    },
    isAllFutureSalesSelected() {
      return Object.values(this.allFutureSalesModels).every(model => model);
    },
    isFutureSalesModelIndeterminate() {
      const isAnyFutureSalesSelected  = Object.values(this.allFutureSalesModels).some(model => model);

      return isAnyFutureSalesSelected && !this.isAllFutureSalesSelected;
    }
  },
  methods: {
    ...mapActions('settings', [
      'setSettingsByKey'
    ])
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/settings";
</style>