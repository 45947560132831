var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-item-container",class:{ 'disabled': _vm.isTabDisabled }},[_c('div',{staticClass:"tab-item-container__block"},[_c('span',{staticClass:"tab-item-container__block-title"},[_vm._v(" "+_vm._s(_vm.$t('Web.Settings.Dc.replenishmentParams'))+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.defaultLeadTimeLabel)+" ")]),_c('SlValidate',{attrs:{"vid":"defaultLeadTimeModel","rules":"required|between:0,9999"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":0,"max":9999},model:{value:(_vm.defaultLeadTimeModel),callback:function ($$v) {_vm.defaultLeadTimeModel=$$v},expression:"defaultLeadTimeModel"}})]}}])})],1),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.defaultOrderCycleLabel)+" ")]),_c('SlValidate',{attrs:{"vid":"defaultOrderCycleModel","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":1,"max":99,"disabled":_vm.isDefaultOrderCycleDisabled},model:{value:(_vm.defaultOrderCycleModel),callback:function ($$v) {_vm.defaultOrderCycleModel=$$v},expression:"defaultOrderCycleModel"}})]}}])})],1),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.shelfDiscardLabel)+" ")]),_c('SlValidate',{attrs:{"vid":"shelfDiscardModel","rules":"required|between:1,99"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":1,"max":99},model:{value:(_vm.shelfDiscardModel),callback:function ($$v) {_vm.shelfDiscardModel=$$v},expression:"shelfDiscardModel"}})]}}])})],1)]),_c('div',{staticClass:"tab-item-container__block"},[_c('span',{staticClass:"tab-item-container__block-title"},[_vm._v(" "+_vm._s(_vm.$t('WiInventorySettings.Ui.grpSafetyStock'))+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('span',{staticClass:"body-1-sb"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Inventory.lbMaximumOf'))+" ")])]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('SlCheckbox',{staticClass:"tab-item-container__label",attrs:{"label":_vm.enableServiceLevelLabel},model:{value:(_vm.enableServiceLevelModel),callback:function ($$v) {_vm.enableServiceLevelModel=$$v},expression:"enableServiceLevelModel"}}),_c('SlValidate',{attrs:{"vid":"serviceLevelModel","rules":"required|between:0.1,99.9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"disabled":!_vm.enableServiceLevelModel,"min":0.1,"max":99.9,"fixed":1,"step":1},model:{value:(_vm.serviceLevelModel),callback:function ($$v) {_vm.serviceLevelModel=$$v},expression:"serviceLevelModel"}})]}}])})],1),_c('div',{staticClass:"tab-item-container__block-row"},[_c('div',{staticClass:"tab-item-container__block-column tab-item-container__column-folded"},[_c('SlCheckbox',{staticClass:"tab-item-container__label",attrs:{"indeterminate":_vm.isFutureSalesModelIndeterminate,"label":this.$t('WiInventorySettings.Ui.chkFutureDemand')},model:{value:(_vm.enableFutureSalesModel),callback:function ($$v) {_vm.enableFutureSalesModel=$$v},expression:"enableFutureSalesModel"}}),_c('div',{staticClass:"tab-item-container__block-column tab-item-container__column-folded tab-item-container__column-offset"},[_c('div',{staticClass:"tab-item-container__block-row"},[_c('SlCheckbox',{staticClass:"tab-item-container__label",attrs:{"label":_vm.salesDemandLabel},model:{value:(_vm.futureSalesNPeriodsEnabled),callback:function ($$v) {_vm.futureSalesNPeriodsEnabled=$$v},expression:"futureSalesNPeriodsEnabled"}}),_c('SlValidate',{attrs:{"vid":"futureSalesNPeriodsModel","rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":0,"max":100,"fixed":1,"step":0.1,"disabled":!_vm.futureSalesNPeriodsEnabled},model:{value:(_vm.futureSalesNPeriodsModel),callback:function ($$v) {_vm.futureSalesNPeriodsModel=$$v},expression:"futureSalesNPeriodsModel"}})]}}])})],1),_c('div',{staticClass:"tab-item-container__block-row"},[_c('SlCheckbox',{staticClass:"tab-item-container__label",attrs:{"label":_vm.lowerEchelonLabel},model:{value:(_vm.futureDcNPeriodsEnabled),callback:function ($$v) {_vm.futureDcNPeriodsEnabled=$$v},expression:"futureDcNPeriodsEnabled"}}),_c('SlValidate',{attrs:{"vid":"futureDcNPeriodsModel","rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":0,"max":100,"fixed":1,"step":0.1,"disabled":!_vm.futureDcNPeriodsEnabled},model:{value:(_vm.futureDcNPeriodsModel),callback:function ($$v) {_vm.futureDcNPeriodsModel=$$v},expression:"futureDcNPeriodsModel"}})]}}])})],1),(_vm.hasBom)?_c('div',{staticClass:"tab-item-container__block-row"},[_c('SlCheckbox',{staticClass:"tab-item-container__label",attrs:{"label":_vm.materialConsumptionLabel},model:{value:(_vm.futureBomNPeriodsEnabled),callback:function ($$v) {_vm.futureBomNPeriodsEnabled=$$v},expression:"futureBomNPeriodsEnabled"}}),_c('SlValidate',{attrs:{"vid":"futureBomNPeriodsModel","rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('SlControlInput',{attrs:{"is-invalid":invalid,"min":0,"max":100,"fixed":1,"step":0.1,"disabled":!_vm.futureBomNPeriodsEnabled},model:{value:(_vm.futureBomNPeriodsModel),callback:function ($$v) {_vm.futureBomNPeriodsModel=$$v},expression:"futureBomNPeriodsModel"}})]}}],null,false,1954577321)})],1):_vm._e()])],1)])]),_c('div',{staticClass:"tab-item-container__block"},[_c('span',{staticClass:"tab-item-container__block-title"},[_vm._v(" "+_vm._s(_vm.$t('WiInventorySettings.Ui.grpPlan'))+" ")]),_c('div',{staticClass:"tab-item-container__block-row"},[_c('span',{staticClass:"tab-item-container__label"},[_vm._v(" "+_vm._s(_vm.$t('Settings.Inventory.lbType'))+" ")]),_c('SlControl',{attrs:{"options":_vm.orderStrategyOptions},model:{value:(_vm.orderStrategyModel),callback:function ($$v) {_vm.orderStrategyModel=$$v},expression:"orderStrategyModel"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }