<template>
  <div
    class="tab-item-container"
    :class="{ 'disabled': disabled }"
  >
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Settings.Ui.grpInventory') }}
      </span>
      <div class="tab-item-container__block-column tab-item-container__column-folded">
        <div class="tab-item-container__block-row">
          <div class="tab-item-container__label">
            {{ $t('Settings.Ui.lbInvSimulation') }}
          </div>
          <SlControl
            v-model="calculationBaseModel"
            :options="inventorySimulationOptions"
          />
        </div>
        <transition name="fade-down">
          <div
            v-if="isSimulationFromToday"
            class="tab-item-container__block-row tab-item-container__row-folded"
          >
            <div class="tab-item-container__label">
              {{ $t('Settings.Ui.lbTimezone') }}
            </div>
            <SlDropdown
              class="tab-item-container__select"
              :target-label="timezoneLabel"
              auto-size
            >
              <template #content>
                <SlDropdownOption
                  v-for="option in timeZones"
                  :key="option.value"
                  select-close
                  @select="setTimezone(option.value)"
                >
                  {{ option.label }}
                </SlDropdownOption>
              </template>
            </SlDropdown>
          </div>
        </transition>
      </div>
      <div class="tab-item-container__block-column tab-item-container__column-folded">
        <SlCheckbox
          v-if="$sl_hasRouteAccess($sl_routes.INTERSTORE)"
          v-model="enableInvOptimModel"
          :label="$t('Settings.Ui.chkEnableInventoryOptimization')"
          :disabled="!hasLocation"
          toggle
        />
        <transition name="fade-down">
          <div
            v-if="$sl_hasRouteAccess($sl_routes.INTERSTORE) && enableInvOptimModel"
            class="tab-item-container__block-row tab-item-container__row-folded"
          >
            <div class="tab-item-container__label">
              {{ $t('Settings.Ui.lbTransferLeadTime') }}
            </div>
            <SlValidate
              v-slot="{ invalid }"
              vid="transferLeadtimeModel"
              rules="required|between:1,9999"
            >
              <SlControlInput
                v-model="transferLeadtimeModel"
                :is-invalid="invalid"
                :min="1"
                :max="9999"
              />
            </SlValidate>
          </div>
        </transition>
      </div>
      <SlCheckbox
        v-model="orderingAvailabilityCheckbox"
        :label="$t('Settings.Ui.chkEnableAllowance')"
      />
      <div class="tab-item-container__block-sub-row">
        <SlCheckbox
          v-model="useInTransitionModel"
          :label="$t('Settings.Ui.chkInTransitionToOnHand')"
        />
        <SlInfoButton
          v-tooltip="getTooltip($t('Settings.Project.InTransitionTooltip'))"
        />
      </div>
      <SlCheckbox
        v-model="movableDemand"
        :label="$t('Settings.Ui.chkDemandMovable')"
      />
      <SlCheckbox
        v-model="constrainBom"
        :label="$t('Settings.Ui.chkConstrainBom')"
      />
      <SlCheckbox
        v-model="constrainDcTransfersModel"
        :disabled="wantNonstoringDcModel"
        selected="constrained"
        not-selected="default"
        :label="$t('Settings.Ui.chkConstrainDcTransferOrders')"
      />
      <div class="tab-item-container__block-row">
        <span class="tab-item-container__label">
          {{ $t('Settings.Ui.lbPreventOvertakePeriod') }}
        </span>
        <SlValidate
          v-slot="{ invalid }"
          vid="preventOvertakePeriodModel"
          rules="required|between:0,120"
        >
          <SlControlInput
            v-model="preventOvertakePeriodModel"
            :is-invalid="invalid"
            :min="0"
            :max="120"
          />
        </SlValidate>
      </div>
      <div
        class="tab-item-container__block-column tab-item-container__column-folded"
      >
        <div
          v-if="isAvgSalesPricePeriodPresent"
          class="tab-item-container__block-row"
        >
          <span class="tab-item-container__label">
            {{ avgSalesPricePeriodLabel }}
          </span>
          <SlValidate
            v-slot="{ invalid }"
            vid="avgSalesPricePeriodModel"
            rules="required|between:1,60"
          >
            <SlControlInput
              v-model="avgSalesPricePeriodModel"
              :is-invalid="invalid"
              :min="1"
              :max="60"
            />
          </SlValidate>
        </div>
        <div class="tab-item-container__row-folded">
          <SlCheckbox
            v-model="avgSalesPriceIncompleteExcluded"
            :label="$t('Settings.Project.AvgSalesPriceIncompleteExcluded')"
            class="tab-item-container__label tab-item-container__checkbox-folded"
          />
        </div>
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Settings.Ui.grpUpdate') }}
      </span>
      <SlCheckbox
        v-model="wantShallowUpdateModel"
        :label="$t('Settings.Ui.chkShallowUpdate')"
      />
      <SlCheckbox
        v-model="wantKeepCoeffsModel"
        :label="$t('Settings.Ui.chkKeepCoeffs')"
      />
      <SlCheckbox
        v-model="wantKeepApprovalsModel"
        :label="$t('Settings.Ui.chkKeepApproved')"
      />
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Settings.Ui.grpKpi2') }}
      </span>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Settings.Ui.lbDaysOfSupplyIn') }}
        </div>
        <div class="tab-item-container__block-sub-row">
          <SlControl
            v-model="supplyUnitModel"
            :options="supplyUnitItems"
          />
          <span class="grey-70 body-1">
            {{ $t('Settings.Project.radioOfSupplyLabel') }}
          </span>
        </div>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Settings.Ui.lbAccuracy') }}
        </div>
        <SlControl
          v-model="accuracyMeasureModel"
          :options="accuracyMeasureItems"
        />
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Settings.Ui.lbOrderCycleUnit') }}
        </div>
        <SlControl
          v-model="orderCycleUnitModel"
          v-tooltip="getTooltip({
            content: $t('Web.Settings.TooltipOrderCycleUnitDisabled'),
            disabled: canEditOrderCycleUnit
          })"
          :options="orderCycleUnitItems"
          :disabled="!canEditOrderCycleUnit"
        />
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Settings.Ui.lbPeriodStart') }}
        </div>
        <SlDropdown
          class="tab-item-container__select"
          :target-label="aggregationStartValue"
          auto-size
        >
          <template #content>
            <SlDropdownOption
              v-for="option in months"
              :key="option.value"
              select-close
              @select="setAggregationStart(option.value)"
            >
              {{ option.text }}
            </SlDropdownOption>
          </template>
        </SlDropdown>
      </div>
      <div class="tab-item-container__block-row">
        <span class="tab-item-container__label">
          {{ $t('Web.Settings.grpCalculateStockAs') }}
        </span>
        <SlControl
          v-model="safetyShelfTypeModel"
          :options="safetyShelfTypeItems"
        />
      </div>
      <div class="tab-item-container__block-row">
        <span class="tab-item-container__label">
          {{ $t('Settings.Ui.lbStockoutDays') }}
        </span>
        <SlControl
          v-model="stockoutDaysAggregationModel"
          :options="stockoutDaysAggregationItems"
        />
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Settings.Ui.lbCurrencySymbol') }}
        </div>
        <SlInput
          v-model="currencyModel"
          max-length="4"
          class="tab-item-container__currency-input"
        />
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Settings.Ui.grpKpi') }}
      </span>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Web.Settings.lbCoc') }}
        </div>
        <div class="tab-item-container__block-row">
          <SlValidate
            v-slot="{ invalid }"
            vid="enableInvOptimModel"
            rules="required|between:0,1000"
          >
            <SlControlInput
              v-model="costOfCapitalModel"
              :is-invalid="invalid"
              :min="0"
              :max="1000"
              :step="1"
            />
          </SlValidate>
        </div>
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Settings.Ui.grpContainersGrouping') }}
      </span>
      <div class="tab-item-container__block-sub-row">
        <SlCheckbox
          v-model="containerGroupingModel"
          :disabled="containerGroupingCheckboxState"
          :label="$t('Settings.Ui.chkWantDefaultContainerID')"
        />
        <SlInfoButton v-tooltip="getTooltip($t('Settings.Project.containerGroupingTooltip'))" />
      </div>
      <span class="tab-item-container__block-sub-title">
        {{ $t('Settings.Ui.grpDefaultContainerCapacity') }}
      </span>
      <div class="tab-item-container__block-row">
        <SlCheckbox
          v-model="containerWantVolumeModel"
          class="tab-item-container__label"
          :label="$t('Settings.Ui.chkVolumeContainerRounding')"
          toggle
        />
        <SlValidate
          v-slot="{ invalid }"
          vid="containerVolumeModel"
          rules="required|between:1,9999999"
        >
          <SlControlInput
            v-model="containerVolumeModel"
            :disabled="!disabled && !containerWantVolumeModel"
            :is-invalid="invalid"
            :min="1"
            :max="9999999"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <SlCheckbox
          v-model="containerWantWeightModel"
          class="tab-item-container__label"
          :label="$t('Settings.Ui.chkWeightContainerRounding')"
          toggle
        />
        <SlValidate
          v-slot="{ invalid }"
          vid="containerWeightModel"
          rules="required|between:1,9999999"
        >
          <SlControlInput
            v-model="containerWeightModel"
            :disabled="!disabled && !containerWantWeightModel"
            :is-invalid="invalid"
            :min="1"
            :max="9999999"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <SlCheckbox
          v-model="containerWantQtyModel"
          class="tab-item-container__label"
          :label="$t('Settings.Ui.chkQtyContainerRounding')"
          toggle
        />
        <SlValidate
          v-slot="{ invalid }"
          vid="containerQtyModel"
          rules="required|between:1,9999999"
        >
          <SlControlInput
            v-model="containerQtyModel"
            :disabled="!disabled && !containerWantQtyModel"
            :is-invalid="invalid"
            :min="1"
            :max="9999999"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-row">
        <SlCheckbox
          v-model="containerWantCostModel"
          class="tab-item-container__label"
          :label="$t('Settings.Ui.chkContainerPrice')"
          toggle
        />
        <SlValidate
          v-slot="{ invalid }"
          vid="containerCostModel"
          rules="required|between:1,9999999"
        >
          <SlControlInput
            v-model="containerCostModel"
            :disabled="!disabled && !containerWantCostModel"
            :is-invalid="invalid"
            :min="1"
            :max="9999999"
          />
        </SlValidate>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { access } from '@/mixins/access';
import { months } from '@/config/utils/dateInfo.config';
import {
  accuracyMeasure,
  accuracyMeasureItems,
  orderCycleUnitItems,
  orderCycleValues,
  safetyShelfTypeItems,
  stockoutDaysAggregationItems,
  supplyUnitItems,
  supplyUnitPeriods,
  inventorySimulationOptions,
  inventorySimulationKeys,
  timeZones
} from '@/config/shared/settings.config';

export default {
  name: 'ProjectTab',
  mixins: [access],
  props: {
    disabled: Boolean
  },
  data() {
    return {
      inventorySimulationKeys,
      supplyUnitPeriods,
      accuracyMeasure,
      orderCycleValues,
      timeZones,
      months: months(this),
      stockoutDaysAggregationItems: stockoutDaysAggregationItems(this),
      safetyShelfTypeItems: safetyShelfTypeItems(this),
      supplyUnitItems: supplyUnitItems(this),
      accuracyMeasureItems: accuracyMeasureItems(this),
      orderCycleUnitItems: orderCycleUnitItems(this)
    };
  },
  inject: [
    'getTooltip'
  ],
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
      canEditOrderCycleUnit: state => state.project.project?.canEditOrderCycleUnit
    }),
    ...mapGetters({
      hasLocation: 'project/hasLocation',
      localUpdateTime: 'project/localUpdateTime'
    }),
    aggregationStartValue() {
      return this.settings && this.months[this.settings?.aggregationStart - 1].text;
    },
    avgSalesPricePeriodLabel() {
      return `${this.$t('Settings.Ui.lbAvgSalesPricePeriod')}, ${this.$t('Settings.Ui.lbAvgSalesPricePeriodPeriods')}`;
    },
    containerGroupingCheckboxState() {
      return !(this.containerWantVolumeModel || this.containerWantWeightModel || this.containerWantQtyModel || this.containerWantCostModel);
    },
    wantNonstoringDcModel() {
      return this.settings?.wantNonstoringDc;
    },
    isAvgSalesPricePeriodPresent() {
      return this.settings?.avgSalesPricePeriod !== null && this.settings?.avgSalesPricePeriod !== undefined ;
    },
    inventorySimulationOptions() {
      return inventorySimulationOptions(this, this.localUpdateTime);
    },
    isSimulationFromToday() {
      return this.settings?.calculationBase === inventorySimulationKeys.TODAY;
    },
    calculationBaseModel: {
      get() {
        return this.settings?.calculationBase;
      },
      set(value) {
        this.setSettingsByKey({ key: 'calculationBase', value });
      }
    },
    wantShallowUpdateModel: {
      get() {
        return this.settings?.wantShallowUpdate;
      },
      set(value) {
        this.setSettingsByKey({ key: 'wantShallowUpdate', value });
      }
    },
    wantKeepCoeffsModel: {
      get() {
        return this.settings?.wantKeepCoeffs;
      },
      set(value) {
        this.setSettingsByKey({ key: 'wantKeepCoeffs', value });
      }
    },
    wantKeepApprovalsModel: {
      get() {
        return this.settings?.wantKeepApprovals;
      },
      set(value) {
        this.setSettingsByKey({ key: 'wantKeepApprovals', value });
      }
    },
    containerGroupingModel: {
      get() {
        return this.settings?.containerGrouping;
      },
      set(value) {
        this.setSettingsByKey({ key: 'containerGrouping', value });
      }
    },
    containerWantVolumeModel: {
      get() {
        return this.settings?.containerWantVolume;
      },
      set(value) {
        this.setSettingsByKey({ key: 'containerWantVolume', value });
      }
    },
    containerVolumeModel: {
      get() {
        return this.settings?.containerVolume;
      },
      set(value) {
        this.setSettingsByKey({ key: 'containerVolume', value });
      }
    },
    containerWantWeightModel: {
      get() {
        return this.settings?.containerWantWeight;
      },
      set(value) {
        this.setSettingsByKey({ key: 'containerWantWeight', value });
      }
    },
    containerWeightModel: {
      get() {
        return this.settings?.containerWeight;
      },
      set(value) {
        this.setSettingsByKey({ key: 'containerWeight', value });
      }
    },
    containerWantQtyModel: {
      get() {
        return this.settings?.containerWantQty;
      },
      set(value) {
        this.setSettingsByKey({ key: 'containerWantQty', value });
      }
    },
    containerQtyModel: {
      get() {
        return this.settings?.containerQty;
      },
      set(value) {
        this.setSettingsByKey({ key: 'containerQty', value });
      }
    },
    containerWantCostModel: {
      get() {
        return this.settings?.containerWantCost;
      },
      set(value) {
        this.setSettingsByKey({ key: 'containerWantCost', value });
      }
    },
    containerCostModel: {
      get() {
        return this.settings?.containerCost;
      },
      set(value) {
        this.setSettingsByKey({ key: 'containerCost', value });
      }
    },
    enableInvOptimModel: {
      get() {
        return this.settings?.enableInvOptim;
      },
      set(value) {
        this.setSettingsByKey({ key: 'enableInvOptim', value });
      }
    },
    transferLeadtimeModel: {
      get() {
        return this.settings?.transferLeadtime;
      },
      set(value) {
        this.setSettingsByKey({ key: 'transferLeadtime', value });
      }
    },
    useInTransitionModel: {
      get() {
        return this.settings?.useInTransition;
      },
      set(value) {
        this.setSettingsByKey({ key: 'useInTransition', value });
      }
    },
    preventOvertakePeriodModel: {
      get() {
        return this.settings?.preventOvertakePeriod;
      },
      set(value) {
        this.setSettingsByKey({ key: 'preventOvertakePeriod', value });
      }
    },
    avgSalesPricePeriodModel: {
      get() {
        return this.settings?.avgSalesPricePeriod;
      },
      set(value) {
        this.setSettingsByKey({ key: 'avgSalesPricePeriod', value });
      }
    },
    orderingAvailabilityCheckbox: {
      get() {
        return this.settings?.wantAllowance;
      },
      set(value) {
        this.setSettingsByKey({ key: 'wantAllowance', value });
      }
    },
    movableDemand: {
      get() {
        return this.settings?.movableDemand;
      },
      set(value) {
        this.setSettingsByKey({ key: 'movableDemand', value });
      }
    },
    constrainBom: {
      get() {
        return this.settings?.constrainBom;
      },
      set(value) {
        this.setSettingsByKey({ key: 'constrainBom', value });
      }
    },
    constrainDcTransfersModel: {
      get() {
        return this.settings?.constrainDcTransfers;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'constrainDcTransfers',
          value
        });
      }
    },
    costOfCapitalModel: {
      get() {
        return this.settings?.costOfCapital;
      },
      set(value) {
        this.setSettingsByKey({ key: 'costOfCapital', value });
      }
    },
    supplyUnitModel: {
      get() {
        return this.settings?.supplyUnit;
      },
      set(value) {
        this.setSettingsByKey({ key: 'supplyUnit', value });
      }
    },
    accuracyMeasureModel: {
      get() {
        return this.settings?.accuracyMeasure;
      },
      set(value) {
        this.setSettingsByKey({ key: 'accuracyMeasure', value });
      }
    },
    orderCycleUnitModel: {
      get() {
        return this.settings?.orderCycleUnit;
      },
      set(value) {
        this.setSettingsByKey({ key: 'orderCycleUnit', value });
      }
    },
    safetyShelfTypeModel: {
      get() {
        return this.settings?.safetyShelfType;
      },
      set(value) {
        this.setSettingsByKey({ key: 'safetyShelfType', value });
      }
    },
    stockoutDaysAggregationModel: {
      get() {
        return this.settings?.stockoutDaysAggregation;
      },
      set(value) {
        this.setSettingsByKey({ key: 'stockoutDaysAggregation', value });
      }
    },
    currencyModel: {
      get() {
        return this.settings?.currency;
      },
      set(value) {
        this.setSettingsByKey({ key: 'currency', value: value.slice(0, 4) });
      }
    },
    avgSalesPriceIncompleteExcluded: {
      get() {
        return this.settings?.avgSalesPriceIncompleteExcluded;
      },
      set(value) {
        this.setSettingsByKey({ key: 'avgSalesPriceIncompleteExcluded', value });
      }
    },
    timezoneLabel() {
      return timeZones.find(zone => zone.value === this.settings?.timezone)?.label;
    }
  },
  watch: {
    containerGroupingCheckboxState(value) {
      if (value) {
        this.setSettingsByKey({ key: 'containerGrouping', value: false });
      }
    }
  },
  methods: {
    ...mapActions('settings', [
      'setSettingsByKey'
    ]),
    setAggregationStart(value) {
      this.setSettingsByKey({ key: 'aggregationStart', value });
    },
    setTimezone(value) {
      this.setSettingsByKey({ key: 'timezone', value });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/settings";
</style>
