<template>
  <div
    class="tab-item-container"
    :class="{ 'disabled': isDisabled }"
  >
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Settings.Ui.grpUpdate') }}
      </span>
      <SlCheckbox
        v-model="autoUpdateModel"
        v-tooltip="getTooltip({
          content: $t('Web.Settings.TooltipAutoUpdateNotSupported'),
          disabled: canAutoUpdate
        })"
        :label="$t('Settings.EnableAutoUpdate')"
        :disabled="!canAutoUpdate"
        toggle
      />
      <transition name="fade-down">
        <div
          v-if="autoUpdateModel"
          class="tab-item-container__block padding-unset"
        >
          <span class="tab-item-container__block-sub-title">
            {{ $t('Settings.Automation.timeSetting') }}
          </span>
          <div class="tab-item-container__block-row">
            <SlRadioButton
              v-model="updateTypeModel"
              :label="$t('WiAutomationControls.Ui.radioDaily')"
              :selected="autoUpdateTypes.DAILY"
              class="tab-item-container__block-radio--button"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="startDateUpdateDaily"
              :rules="{
                required: updateTypeModel === autoUpdateTypes.DAILY,
                sl_valid_time: true
              }"
            >
              <SlTimePicker
                v-model="dailyAtUpdateModel"
                :disabled="!isDailyUpdateType"
                :is-invalid="invalid"
                class="tab-item-container__time-picker"
              />
            </SlValidate>
            <div class="tab-item-container__block-radio--label">
              {{ utcTimeOffsetLabel }}
            </div>
          </div>
          <div class="tab-item-container__block-row">
            <SlRadioButton
              v-model="updateTypeModel"
              :label="$t('WiAutomationControls.Ui.radioByHours')"
              :selected="autoUpdateTypes.BY_HOURS"
              class="tab-item-container__block-radio--button"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="startDateUpdateHours"
              :rules="{
                required: updateTypeModel === autoUpdateTypes.BY_HOURS,
                sl_valid_time: {
                  format: timeFormats.t_24h
                }
              }"
            >
              <SlTimePicker
                v-model="everyUpdateModel"
                :disabled="isDailyUpdateType || !autoUpdateModel"
                :is-invalid="invalid"
                :format="timeFormats.t_24h"
                class="tab-item-container__time-picker"
              />
            </SlValidate>
            <div class="tab-item-container__block-radio--label">
              {{ getNextTimeLabel(updateNextTime) }}
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div
      v-if="canExport"
      class="tab-item-container__block"
    >
      <span class="tab-item-container__block-title">
        {{ $t('Settings.Ui.grpAutoExport') }}
      </span>
      <SlCheckbox
        v-model="autoExportModel"
        v-tooltip="autoExportTooltip"
        :label="$t('Settings.EnableAutoExport')"
        :disabled="!canManageAutoExport"
        toggle
      />
      <transition name="fade-down">
        <div
          v-if="autoExportModel"
          class="tab-item-container__block padding-unset"
        >
          <span class="tab-item-container__block-sub-title">
            {{ $t('Settings.Automation.timeSetting') }}
          </span>
          <SlAlert
            v-if="isSimulationFromToday"
            :accent="$t('Settings.Ui.lbTimezoneTooltip', { 1: localUpdateTime })"
            type="info-extra"
          >
            <template #icon>
              <icon
                data="@icons/info.svg"
                class="fill-off size-20"
              />
            </template>
          </SlAlert>
          <div class="tab-item-container__block-row">
            <SlRadioButton
              v-model="exportTypeModel"
              :label="$t('WiAutomationControls.Ui.radioDaily')"
              :selected="autoUpdateTypes.DAILY"
              class="tab-item-container__block-radio--button"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="startDateExportDaily"
              :rules="{
                required: exportTypeModel === autoUpdateTypes.DAILY,
                sl_valid_time: true
              }"
            >
              <SlTimePicker
                v-model="dailyAtExportModel"
                :disabled="!isDailyExportType"
                :is-invalid="invalid"
                class="tab-item-container__time-picker"
              />
            </SlValidate>
            <div class="tab-item-container__block-radio--label">
              {{ utcTimeOffsetLabel }}
            </div>
          </div>
          <div class="tab-item-container__block-row">
            <SlRadioButton
              v-model="exportTypeModel"
              :label="$t('WiAutomationControls.Ui.radioByHours')"
              :selected="autoUpdateTypes.BY_HOURS"
              class="tab-item-container__block-radio--button"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="startDateExportHours"
              :rules="{
                required: exportTypeModel === autoUpdateTypes.BY_HOURS,
                sl_valid_time: {
                  format: timeFormats.t_24h
                }
              }"
            >
              <SlTimePicker
                v-model="everyExportModel"
                :disabled="isDailyExportType"
                :is-invalid="invalid"
                :format="timeFormats.t_24h"
                class="tab-item-container__time-picker"
              />
            </SlValidate>
            <div class="tab-item-container__block-radio--label">
              {{ getNextTimeLabel(exportNextTime) }}
            </div>
          </div>
          <div class="tab-item-container__block-sub-title">
            <span class="heading-5-sb grey-90">
              {{ $t('Web.Settings.Automation.exportSettings') }}
            </span>
            <span class="body-1 grey-70">
              {{ $t('Web.Settings.Automation.exportSettingsSubtitle') }}
            </span>
          </div>
          <template v-for="(item, index) in exportCheckboxes">
            <SlCheckbox
              v-if="canExport & item.visibilityFgs"
              :key="item.label"
              :value="!!(autoExportFgs & item.valueFgs)"
              :disabled="!autoExportModel || checkIsAutoExportDisabled(index)"
              :label="item.label"
              @change="(val) => handleAutoExportFgsChange(val, item.valueFgs)"
            />
          </template>
        </div>
      </transition>
    </div>
    <div
      v-if="$sl_hasAccess($sl_features.updateConstrainedOrderingPlan)"
      class="tab-item-container__block"
    >
      <span class="tab-item-container__block-title">
        {{ $t('Web.Settings.ConstrainedTitle') }}
      </span>
      <SlCheckbox
        v-model="autoCalcModel"
        :label="$t('Web.Settings.ConstrainedAutoCalc')"
        toggle
      />
      <transition name="fade-down">
        <div
          v-if="autoCalcModel"
          class="tab-item-container__block padding-unset"
        >
          <span class="tab-item-container__block-sub-title">
            {{ $t('Settings.Automation.timeSetting') }}
          </span>
          <div class="tab-item-container__block-row">
            <SlRadioButton
              v-model="calcTypeModel"
              :label="$t('WiAutomationControls.Ui.radioDaily')"
              :selected="autoUpdateTypes.DAILY"
              class="tab-item-container__block-radio--button"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="startDateCalcDaily"
              :rules="{
                required: calcTypeModel === autoUpdateTypes.DAILY,
                sl_valid_time: true
              }"
            >
              <SlTimePicker
                v-model="dailyAtCalcModel"
                :disabled="!isDailyCalcType"
                :is-invalid="invalid"
                class="tab-item-container__time-picker"
              />
            </SlValidate>
            <div class="tab-item-container__block-radio--label">
              {{ utcTimeOffsetLabel }}
            </div>
          </div>
          <div class="tab-item-container__block-row">
            <SlRadioButton
              v-model="calcTypeModel"
              :label="$t('WiAutomationControls.Ui.radioByHours')"
              :selected="autoUpdateTypes.BY_HOURS"
              class="tab-item-container__block-radio--button"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="startDateCalcHours"
              :rules="{
                required: calcTypeModel === autoUpdateTypes.BY_HOURS,
                sl_valid_time: {
                  format: timeFormats.t_24h
                }
              }"
            >
              <SlTimePicker
                v-model="everyCalcModel"
                :disabled="isDailyCalcType || !autoCalcModel"
                :is-invalid="invalid"
                :format="timeFormats.t_24h"
                class="tab-item-container__time-picker"
              />
            </SlValidate>
            <div class="tab-item-container__block-radio--label">
              {{ getNextTimeLabel(calcNextTime) }}
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import { access } from '@/mixins/access';
import { autoUpdateTypes, exportCheckboxes, inventorySimulationKeys } from '@/config/shared/settings.config';
import { timeFormats } from '@/config/shared/datePicker.config';
import { dateFormats, utcTimezoneOffsetTime } from '@/helpers/date/date';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'AutomationTab',
  mixins: [access],
  props: {
    disabled: Boolean
  },
  data() {
    return {
      autoUpdateTypes,
      timeFormats,
      exportCheckboxes: exportCheckboxes(this)
    };
  },
  computed: {
    ...mapState('settings', [
      'settings'
    ]),
    ...mapGetters({
      canExport: 'project/canExport',
      canAutoExport: 'project/canAutoExport',
      canAutoUpdate: 'project/canAutoUpdate',
      localUpdateTime: 'project/localUpdateTime',
      isSourceFile: 'spreadsheet/isSourceFile',
      isSpreadsheetConnection: 'spreadsheet/isSpreadsheetConnection'
    }),
    hasAutoExportAccess() {
      return this.$sl_hasAccess(this.$sl_features.createPlannedOrders) || this.$sl_hasAccess(this.$sl_features.exportTables);
    },
    canManageAutoExport() {
      return this.canAutoExport && this.hasAutoExportAccess;
    },
    isDailyUpdateType() {
      return this.updateTypeModel === autoUpdateTypes.DAILY;
    },
    isDailyCalcType() {
      return this.calcTypeModel === autoUpdateTypes.DAILY;
    },
    isDisabled() {
      return this.disabled || this.isSpreadsheetConnection && this.isSourceFile;
    },
    updateNextTime() {
      const [hours, minutes] = this.everyUpdateModel.split(':');

      return moment()
        .add({  hours, minutes })
        .format( dateFormats.SHORT_TIME);
    },
    calcNextTime() {
      const [hours, minutes] = this.everyCalcModel.split(':');

      return moment()
        .add({  hours, minutes })
        .format( dateFormats.SHORT_TIME);
    },
    autoExportFgs() {
      return this.settings.autoExport.fgs;
    },
    isSimulationFromToday() {
      return this.settings?.calculationBase === inventorySimulationKeys.TODAY;
    },
    isDailyExportType() {
      return this.exportTypeModel === autoUpdateTypes.DAILY;
    },
    exportNextTime() {
      const [hours, minutes] = this.everyExportModel.split(':');

      return moment()
        .add({  hours, minutes })
        .format( dateFormats.SHORT_TIME);
    },
    utcTimeOffsetLabel() {
      return `UTC ${utcTimezoneOffsetTime}`;
    },
    autoExportTooltip() {
      return getTooltip({
        content: !this.hasAutoExportAccess
          ? this.$t('Web.Settings.TooltipAutoExportNotAllowed')
          : this.$t('Web.Settings.TooltipAutoExportNotSupported'),
        disabled: this.canManageAutoExport
      });
    },
    autoUpdateModel: {
      get() {
        return this.canAutoUpdate && this.settings?.autoUpdate?.enable;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoUpdate',
          subKey: 'enable',
          value
        });
      }
    },
    updateTypeModel: {
      get() {
        return this.settings?.autoUpdate?.type;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoUpdate',
          subKey: 'type',
          value
        });
      }
    },
    dailyAtUpdateModel: {
      get() {
        return this.settings?.autoUpdate?.dailyModel;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoUpdate',
          subKey: 'dailyModel',
          value
        });
      }
    },
    everyUpdateModel: {
      get() {
        return this.settings?.autoUpdate?.everyModel;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoUpdate',
          subKey: 'everyModel',
          value
        });
      }
    },
    autoCalcModel: {
      get() {
        return this.settings?.autoUpdateConstrainedOrderingPlan?.enable;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoUpdateConstrainedOrderingPlan',
          subKey: 'enable',
          value
        });
      }
    },
    calcTypeModel: {
      get() {
        return this.settings?.autoUpdateConstrainedOrderingPlan?.type;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoUpdateConstrainedOrderingPlan',
          subKey: 'type',
          value
        });
      }
    },
    dailyAtCalcModel: {
      get() {
        return this.settings?.autoUpdateConstrainedOrderingPlan?.dailyModel;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoUpdateConstrainedOrderingPlan',
          subKey: 'dailyModel',
          value
        });
      }
    },
    everyCalcModel: {
      get() {
        return this.settings?.autoUpdateConstrainedOrderingPlan?.everyModel;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoUpdateConstrainedOrderingPlan',
          subKey: 'everyModel',
          value
        });
      }
    },
    autoExportModel: {
      get() {
        return this.canManageAutoExport && this.settings?.autoExport?.enable;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoExport',
          subKey: 'enable',
          value
        });
      }
    },
    exportTypeModel: {
      get() {
        return this.settings?.autoExport?.type;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoExport',
          subKey: 'type',
          value
        });
      }
    },
    dailyAtExportModel: {
      get() {
        return this.settings?.autoExport?.dailyModel;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoExport',
          subKey: 'dailyModel',
          value
        });
      }
    },
    everyExportModel: {
      get() {
        return this.settings?.autoExport?.everyModel;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'autoExport',
          subKey: 'everyModel',
          value
        });
      }
    }
  },
  methods: {
    ...mapActions('settings', [
      'setSettingsByKey'
    ]),
    getTooltip,
    checkIsAutoExportDisabled(index) {
      if (index > 0) {
        return !this.$sl_hasAccess(this.$sl_features.exportTables);
      }

      return !this.$sl_hasAccess(this.$sl_features.createPlannedOrders);
    },
    getNextTimeLabel(nextTime) {
      return this.$t('WiAutomationControls.Other.NextTime',
        {
          1: nextTime,
          2: this.utcTimeOffsetLabel
        });
    },
    handleAutoExportFgsChange(value, fgs) {
      this.setSettingsByKey({
        key: 'autoExport',
        subKey: 'fgs',
        value: value
          ? this.autoExportFgs + fgs
          : this.autoExportFgs - fgs
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/settings";

.tab-item-container__block-row {
  align-items: center;
}
</style>
