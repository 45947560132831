<template>
  <div
    class="tab-item-container"
    :class="{ 'disabled': isTabDisabled }"
  >
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('Main.CustomizeColumnsSubmenus.ReplenishmentParams') }}
      </span>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ defaultLeadTimeLabel }}
        </div>
        <div class="tab-item-container__block-row">
          <SlValidate
            v-slot="{ invalid }"
            vid="defaultLeadTimeModel"
            rules="required|between:0,9999"
          >
            <SlControlInput
              v-model="defaultLeadTimeModel"
              :is-invalid="invalid"
              :min="0"
              :max="9999"
            />
          </SlValidate>
        </div>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ defaultOrderCycleLabel }}
        </div>
        <div class="tab-item-container__block-row">
          <SlValidate
            v-slot="{ invalid }"
            vid="defaultOrderCycleModel"
            rules="required|between:1,99"
          >
            <SlControlInput
              v-model="defaultOrderCycleModel"
              :is-invalid="invalid"
              :min="1"
              :max="99"
              :disabled="isDefaultOrderCycleDisabled"
            />
          </SlValidate>
        </div>
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ shelfDiscardLabel }}
        </div>
        <div class="tab-item-container__block-row">
          <SlValidate
            v-slot="{ invalid }"
            vid="shelfDiscardModel"
            rules="required|between:1,99"
          >
            <SlControlInput
              v-model="shelfDiscardModel"
              :is-invalid="invalid"
              :min="1"
              :max="99"
            />
          </SlValidate>
        </div>
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('WiInventorySettings.Ui.grpSafetyStock') }}
      </span>
      <div class="tab-item-container__block-row body-1-sb grey-80">
        {{ $t('Settings.Inventory.lbMaximumOf') }}
      </div>
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__block-column">
          <SlCheckbox
            v-model="enableServiceLevelModel"
            :label="enableServiceLevelLabel"
            class="tab-item-container__label"
          />
          <div class="tab-item-container__formula tab-item-container__row-folded">
            <div class="tab-item-container__label">
              <div class="tab-item-container__label--filled">
                <span class="body-1 grey-80">
                  {{ $t('Settings.Inventory.CalculatedValueLabel') }}
                </span>
                {{ serviceLevelFormula }}
              </div>
            </div>
          </div>
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="serviceLevelModel"
          rules="required|between:0.1,99.9"
        >
          <SlControlInput
            v-model="serviceLevelModel"
            :is-invalid="invalid"
            :disabled="!enableServiceLevelModel"
            :min="0.1"
            :max="serviceLevelMaxValue"
            :fixed="1"
          />
        </SlValidate>
      </div>
      <div class="tab-item-container__block-column tab-item-container__column-folded">
        <SlCheckbox
          v-model="enableFutureSalesModel"
          :indeterminate="isFutureSalesModelIndeterminate"
          :label="this.$t('WiInventorySettings.Ui.chkFutureDemand')"
          class="tab-item-container__label"
        />
        <div class="tab-item-container__block-column tab-item-container__column-folded tab-item-container__column-offset">
          <div class="tab-item-container__block-row">
            <SlCheckbox
              v-model="futureSalesNPeriodsEnabled"
              :label="salesDemandLabel"
              class="tab-item-container__label"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="futureSalesNPeriodsModel"
              rules="required|between:0,100"
            >
              <SlControlInput
                v-model="futureSalesNPeriodsModel"
                :is-invalid="invalid"
                :min="0"
                :max="100"
                :fixed="1"
                :step="0.1"
                :disabled="!futureSalesNPeriodsEnabled"
              />
            </SlValidate>
          </div>
          <div
            v-if="hasBom"
            class="tab-item-container__block-row"
          >
            <SlCheckbox
              v-model="futureBomNPeriodsEnabled"
              :label="materialConsumptionLabel"
              class="tab-item-container__label"
            />
            <SlValidate
              v-slot="{ invalid }"
              vid="futureBomNPeriodsModel"
              rules="required|between:0,100"
            >
              <SlControlInput
                v-model="futureBomNPeriodsModel"
                :is-invalid="invalid"
                :min="0"
                :max="100"
                :fixed="1"
                :step="0.1"
                :disabled="!futureBomNPeriodsEnabled"
              />
            </SlValidate>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('WiInventorySettings.Ui.grpColumns') }}
      </span>
      <SlCheckbox
        v-model="demandViewModel"
        :label="$t('WiInventorySettings.Ui.chkDemandMaterial')"
        class="sl-checkbox"
      />
      <div class="tab-item-container__block-row">
        <div class="tab-item-container__label">
          {{ $t('Settings.Inventory.lbActual') }}
        </div>
        <SlValidate
          v-slot="{ invalid }"
          vid="actualSalesModel"
          rules="required|between:0,99"
        >
          <SlControlInput
            v-model="nActualModel"
            :is-invalid="invalid"
            :min="0"
            :max="99"
          />
        </SlValidate>
      </div>
    </div>
    <div class="tab-item-container__block">
      <span class="tab-item-container__block-title">
        {{ $t('WiInventorySettings.Ui.grpPlan') }}
      </span>
      <div class="tab-item-container__block-row">
        <span class="tab-item-container__label">
          {{ $t('Settings.Inventory.lbType') }}
        </span>
        <SlControl
          v-model="orderStrategyModel"
          :options="orderStrategyOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  orderStrategyOptions,
  orderCycleUnitLabelByValue,
  orderCycleValues,
  orderStrategyKeys
} from '@/config/shared/settings.config';
import { normalInverse } from '@/helpers/formula/normalInverse';

export default {
  name: 'InventoryTab',
  props: {
    disabled: Boolean
  },
  data() {
    return {
      orderStrategyKeys,
      serviceLevelMaxValue: 99.9
    };
  },
  computed: {
    ...mapState('settings', [
      'settings'
    ]),
    ...mapGetters({
      hasInventory: 'project/hasInv',
      hasBom: 'project/hasBom',
      period: 'project/period'
    }),
    orderCycleUnitLabelByValue() {
      return orderCycleUnitLabelByValue(this.period, this);
    },
    orderCycleUnitValue() {
      return this.settings?.orderCycleUnit;
    },
    orderCycleUnitLabel() {
      return this.orderCycleUnitLabelByValue[this.orderCycleUnitValue];
    },
    serviceLevelFormula() {
      return this.$t(`Settings.Formula${+this.formulaSquareRootValue || ''}`, {
        1: this.normalInverseValue
      });
    },
    normalInverseValue() {
      return normalInverse(Math.max(+this.serviceLevelModel, 50) / 100).toFixed(3);
    },
    formulaSquareRootValue() {
      return this.orderStrategyModel === orderStrategyKeys.MINMAX;
    },
    isTabDisabled() {
      return this.disabled || !this.hasInventory;
    },
    isDefaultOrderCycleDisabled() {
      return this.orderCycleUnitValue === orderCycleValues.LEADTIME;
    },
    orderStrategyOptions() {
      return orderStrategyOptions(this);
    },
    defaultLeadTimeLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbLeadTime')}, ${this.$t('WiInventorySettings.Ui.lbLeadTime2')}`;
    },
    defaultOrderCycleLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbOrderCycle')}, ${this.orderCycleUnitLabel}`;
    },
    shelfDiscardLabel() {
      return `${this.$t('WiInventorySettings.Ui.lbDiscard')}, %`;
    },
    enableServiceLevelLabel() {
      return `${this.$t('WiInventorySettings.Ui.chkServiceLevel')}, ${this.$t('WiInventorySettings.Ui.lbServiceLevelPc')}`;
    },
    salesDemandLabel() {
      return `${this.$t('Web.Settings.Inventory.salesDemandLabel')}, ${this.$t(`Common.AfterComma.${this.period}`)}`;
    },
    materialConsumptionLabel() {
      return `${this.$t('Web.Settings.Inventory.materialConsumptionLabel')}, ${this.$t(`Common.AfterComma.${this.period}`)}`;
    },
    defaultLeadTimeModel: {
      get() {
        return this.settings?.inventory?.defaultLeadTime;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'inventory',
          subKey: 'defaultLeadTime',
          value
        });
      }
    },
    defaultOrderCycleModel: {
      get() {
        return this.settings?.inventory?.defaultOrderCycle ?? '';
      },
      set(value) {
        this.setSettingsByKey({
          key: 'inventory',
          subKey: 'defaultOrderCycle',
          value
        });
      }
    },
    shelfDiscardModel: {
      get() {
        return this.settings?.inventory?.shelfDiscard;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'inventory',
          subKey: 'shelfDiscard',
          value
        });
      }
    },
    enableServiceLevelModel: {
      get() {
        return this.settings?.inventory?.enableServiceLevel;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'inventory',
          subKey: 'enableServiceLevel',
          value
        });
      }
    },
    serviceLevelModel: {
      get() {
        return this.settings?.inventory?.serviceLevel;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'inventory',
          subKey: 'serviceLevel',
          value: +value > this.serviceLevelMaxValue
            ? this.serviceLevelMaxValue
            : value
        });
      }
    },
    enableFutureSalesModel: {
      get() {
        return Object.values(this.allFutureSalesModels).some(model => model);
      },
      set(value) {
        Object.keys(this.allFutureSalesModels).forEach(modelKey =>
          this.setSettingsByKey({
            key: 'inventory',
            subKey: modelKey,
            value
          })
        );
      }
    },
    futureSalesNPeriodsModel: {
      get() {
        return this.settings?.inventory?.futureSalesNPeriods;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'inventory',
          subKey: 'futureSalesNPeriods',
          value
        });
      }
    },
    futureBomNPeriodsModel: {
      get() {
        return this.settings?.inventory?.futureBomNPeriods;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'inventory',
          subKey: 'futureBomNPeriods',
          value
        });
      }
    },
    demandViewModel: {
      get() {
        return this.settings?.demandView;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'demandView',
          value
        });
      }
    },
    nActualModel: {
      get() {
        return this.settings?.nActual;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'nActual',
          value
        });
      }
    },
    orderStrategyModel: {
      get() {
        return this.settings?.inventory?.orderStrategy;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'inventory',
          subKey: 'orderStrategy',
          value
        });
      }
    },
    futureSalesNPeriodsEnabled: {
      get() {
        return this.settings?.inventory?.futureSalesNPeriodsEnabled;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'inventory',
          subKey: 'futureSalesNPeriodsEnabled',
          value
        });
      }
    },
    futureBomNPeriodsEnabled: {
      get() {
        return this.settings?.inventory?.futureBomNPeriodsEnabled;
      },
      set(value) {
        this.setSettingsByKey({
          key: 'inventory',
          subKey: 'futureBomNPeriodsEnabled',
          value
        });
      }
    },
    allFutureSalesModels() {
      return {
        futureSalesNPeriodsEnabled: this.futureSalesNPeriodsEnabled,
        ...(this.hasBom) && {
          futureBomNPeriodsEnabled: this.futureBomNPeriodsEnabled
        }
      };
    },
    isAllFutureSalesSelected() {
      return Object.values(this.allFutureSalesModels).every(model => model);
    },
    isFutureSalesModelIndeterminate() {
      const isAnyFutureSalesSelected  = Object.values(this.allFutureSalesModels).some(model => model);

      return isAnyFutureSalesSelected && !this.isAllFutureSalesSelected;
    }
  },
  methods: {
    ...mapActions('settings', [
      'setSettingsByKey'
    ])
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/settings";
</style>