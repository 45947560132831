<template>
  <SubPageWrapper empty-cols>
    <template #loader>
      <SlOverlay :show="is_fetching">
        <SlLoader :text="$t('Web.Loading.LoadingData')" />
      </SlOverlay>
    </template>
    <template #left-col>
      <SlTabList
        :tabs="tabs"
        :value="tabModel"
      />
    </template>
    <template #breadcrumbs>
      <SlBreadcrumb active>
        {{ $t('Main.Ui.acSettings') }}
      </SlBreadcrumb>
    </template>
    <template #actions>
      <div class="settings-wrapper__header-buttons">
        <SlButton
          variant="secondary"
          color="grey"
          :disabled="!hasChanges"
          @click="handleCancel"
        >
          {{ $t('Web.Collections.Discard') }}
        </SlButton>
        <SlButton
          :disabled="!hasChanges"
          @click="handleSave()"
        >
          {{ $t('Web.Collections.SaveChanges') }}
        </SlButton>
      </div>
    </template>
    <ContentBlock>
      <SlTabContent
        v-for="tab in tabs"
        :key="tab.value"
        :value="tab.value"
        :tab-value="tabModel"
      >
        <ValidationObserver
          :ref="`${tab.value}-observer`"
          slim
        >
          <component
            :is="currentTab"
            class="settings-wrapper__component"
            :disabled="isDisabledSettings"
          />
        </ValidationObserver>
      </SlTabContent>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { access } from '@/mixins/access';
import { modal } from '@/mixins/modal';
import InventoryTab from '@/components/Settings/Tabs/InventoryTab';
import ProjectTab from '@/components/Settings/Tabs/ProjectTab';
import DCTab from '@/components/Settings/Tabs/DCTab';
import AutomationTab from '@/components/Settings/Tabs/AutomationTab';
import AbcAnalysisTab from '@/components/Settings/Tabs/AbcAnalysisTab';
import ForecastTab from '@/components/Settings/Tabs/ForecastTab';
import modalIds from '@/config/shared/modalsId.config';
import { settingsTabByRoute, settingsTabItems, settingsTabKeys } from '@/config/shared/settings.config';
import { routeNames } from '@/config/router/router.config';
import statusCodes from '@/config/utils/statusCodes.config';
import { getTooltip } from '@/helpers/shared/tooltip';
import { preventTabClose } from '@/helpers/shared/webAPI';
import { checkEqualObject } from '@/helpers/utils/checkEqualObject';

export default {
  name: 'Settings',
  components: {
    AbcAnalysisTab,
    ForecastTab,
    AutomationTab,
    InventoryTab,
    ProjectTab,
    DCTab
  },
  mixins: [access, modal],
  data() {
    return {
      tabModel: null,
      routeNames,
      modalIds,
      tabsValidationState: {},
      getTooltip
    };
  },
  provide() {
    return {
      getTooltip: this.getTooltip
    };
  },
  computed: {
    ...mapState('settings', [
      'settings',
      'settings_cache',
      'is_fetching'
    ]),
    ...mapGetters({
      isFreePlan: 'initialization/isFreePlan',
      isSpreadsheetConnection: 'spreadsheet/isSpreadsheetConnection'
    }),
    routerHash() {
      return this.$route.hash;
    },
    tabs() {
      const settingsTabs = settingsTabItems(this).map(tab => {
        if (tab.value !== settingsTabKeys.AUTOMATION) {
          return {
            ...tab,
            hash: `#${tab.value}`
          };
        }

        return {
          ...tab,
          icon: 'upgrade-plan',
          restricted: this.isFreePlan,
          hash: `#${tab.value}`
        };
      });

      if (this.$sl_hasProjectAccess) {
        return settingsTabs;
      }

      return [settingsTabs[0]];
    },
    isDisabledSettings() {
      return !this.$sl_hasAccess(this.$sl_features.projectSettings);
    },
    currentTab() {
      return this.tabModel ? settingsTabItems(this).find(tab => tab.value === this.tabModel).component : '';
    },
    hasChanges() {
      if (this.settings_cache === null) {
        return false;
      }

      return !checkEqualObject(this.settings, this.settings_cache);
    },
    tabsLabels() {
      return settingsTabItems(this).reduce((acc, tab) => ({ ...acc, [tab.value]: tab.label }), {});
    }
  },
  watch: {
    $route() {
      this.matchRouterHash();
    },
    settings(val) {
      this.setSettingsCache(val);
    },
    async tabModel(current, old) {
      if (!old) {
        return;
      }

      const observer = this.$refs[`${old}-observer`][0];

      if (!observer) {
        return this.isValid = true;
      }

      this.tabsValidationState[old] = await observer.validate();
    },
    hasChanges(val) {
      preventTabClose(val);
    }
  },
  async beforeMount() {
    this.matchRouterHash();

    try {
      await this.fetchSettings();

      this.setSettingsCache(this.settings);

      if (this.isSpreadsheetConnection) {
        this.fetchSpreadsheetInfo();
      }
    } finally {
      this.setUpdateStatus(false);
    }
  },
  beforeDestroy() {
    preventTabClose();
    this.setUpdateStatus(true);
  },
  beforeRouteLeave(to, from, next) {
    this.handleExit(next);
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      return next();
    }

    this.handleExit(next);
  },
  methods: {
    ...mapActions({
      fetchSettings: 'settings/fetchSettings',
      setSettingsCache: 'settings/setSettingsCache',
      fetchSpreadsheetInfo: 'spreadsheet/fetchSpreadsheetInfo',
      cancelSettingsEdit: 'settings/cancelSettingsEdit',
      saveSettings: 'settings/saveSettings',
      setUpdateStatus: 'lastChanges/setUpdateStatus'
    }),
    handleExit(next) {
      if (!this.hasChanges) {
        return next();
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.UnsavedConfirm.TitleUnsavedChanges'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextUnsavedChanges'),
        icon: 'style_save_double',
        discardText: this.$t('Web.Modals.UnsavedConfirm.ButtonExitDiscard'),
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonSave'),
        discardCallback: () => {
          this.cancelSettingsEdit();

          next();
        },
        confirmCallback: () => {
          this.handleSave(next);
        }
      });
    },
    handleCancel() {
      if (!this.hasChanges) {
        return;
      }

      this.showModal(modalIds.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.UnsavedConfirm.TitleDiscardChanges'),
        text: this.$t('Web.Modals.UnsavedConfirm.TextDiscardChanges'),
        icon: 'style_save_double',
        confirmText: this.$t('Web.Modals.UnsavedConfirm.ButtonDiscard'),
        confirmCallback: this.cancelSettingsEdit
      });
    },
    async handleSave(next) {
      if (this.isDisabledSettings) {
        return;
      }

      this.tabsValidationState[this.tabModel] = await this.validateTab();

      const invalidTabs = Object.keys(this.tabsValidationState)
        .filter(key => !this.tabsValidationState[key])
        .map(key => this.tabsLabels[key]);

      if (invalidTabs.length) {
        const invalidTabsNames = invalidTabs.join(', ');

        this.$notify({
          type: 'error',
          title: this.$t('Web.Notification.SettingsValidationErrorTitle'),
          text: this.$t('Web.Notification.SettingsValidationErrorText', { 1: invalidTabsNames }),
          duration: -1
        });

        return false;
      }

      try {
        const response = await this.saveSettings({
          i18n: this.$root.$i18n
        });

        const status = response?.status;

        if (status === statusCodes.OK) {
          this.$notify({
            type: 'success',
            text: this.$t('Web.Notification.SettingsSaveSuccess'),
            duration: 7000
          });

          if (next) {
            next();
          } else {
            this.$router.back();
          }
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          text: e?.message || this.$t('Web.Notification.SettingsSaveError'),
          duration: 7000
        });

        return false;
      }
    },
    matchRouterHash() {
      const tabKey = this.routerHash.replace('#', '');

      if (settingsTabByRoute[tabKey]) {
        this.tabModel = settingsTabByRoute[tabKey];

        return;
      }

      if (Object.values(settingsTabKeys).includes(tabKey)) {
        this.tabModel = tabKey;

        return;
      }

      this.tabModel = settingsTabKeys.PROJECT;

      return this.$router.replace({
        name: this.$sl_routeName,
        hash: `#${settingsTabKeys.PROJECT}`
      });
    },
    async validateTab() {
      const observer = this.$refs[`${this.tabModel}-observer`][0];

      if (!observer) {
        return true;
      }

      return observer.validate();
    }
  }
};
</script>

<style lang="scss" scoped >
@import "@/style/components/settings/settings-wrapper";
</style>
